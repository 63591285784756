import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import { isAUSRegion } from 'utils/misc';

import Button from '@material-ui/core/Button';
import queryString from 'query-string';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    CircularProgress,
    TextField,
    MenuItem,
    FormHelperText
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import STDTables from './Tables/STDTables';
import AUSTables from './Tables/AUSTables';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { _collector, _redemption } from 'std';
import { validate } from 'utils/validate';

function Redemptions(props) {
    const { redemptionOptions, handleSaveRedemptionOptions, operator, history, collectors, theme } = props;

    const [redemptions, setRedemptions] = useState();
    //const [completedRedemptions, setCompletedRedemptions] = useState();
    const [processingRedemptions, setProcessingRedemptions] = useState();
    const [selectedCheque, setSelectedCheque] = useState([]);
    const [selectedBankTransfer, setSelectedBankTransfer] = useState([]);
    const [selectedNonCheque, setSelectedNonCheque] = useState([]);
    const [selectedProcessing, setSelectedProcessing] = useState([]);
    const [selectedDriverRedemption, setSelectedDriverRedemption] = useState([]);
    const [selectedProcessingDriver, setSelectedProcessingDriver] = useState([]);
    const [selectedCash, setSelectedCash] = useState([]);
    const [paymentTypeToComplete, setPaymentTypeToComplete] = useState([]);
    const [cashedOutBy, setCashedOutBy] = useState('');
    const [emailForAPIRedemption, setEmailForAPIRedemption] = useState('');
    const [redemptionTypesToComplete, setRedemptionTypesToComplete] = useState([]);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [batches, setBatches] = useState();
    const [inProgress, setInProgress] = useState();

    const [selectedReivewEtransfer, setSelectedReivewEtransfer] = useState([]);
    const [selectedReivewBtransfer, setSelectedReivewBtransfer] = useState([]);
    const [selectedReivewCheque, setSelectedReivewCheque] = useState([]);
    const [selectedReivewDriverTransfer, setSelectedReivewDriverTransfer] = useState([]);
    const [approvingReivew, setApprovingReview] = useState(false);
    const [rejectingRedemptions, setRejectingRedemptions] = useState(false);
    const [rejectingProcessing, setRejectingProcessing] = useState(false);

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const useAPIRedemptions = _.get(redemptionOptions, 'paymentsAPI', 'None') !== 'None';

    const fetchRedemptionsAndBatches = async () => {
        setInProgress(true);

        let res = null,
            resProcessingRedemptions = null,
            res1 = null;

        //don't need to load completed redemptions for STD here as the paginated table handles loading them
        if (isAUSRegion()) {
            const qs = queryString.parse(window.location.search);

            let stateFilters = '';

            for (let key in qs) {
                if (qs[key]) {
                    const searchKey = key;
                    stateFilters += `&${searchKey}=${encodeURIComponent(qs[key])}`;
                }
            }
            let pendingUrl = '/redemptions/getAllPendingRedemptions';

            if (!_.isEmpty(stateFilters)) {
                pendingUrl += '?' + stateFilters;
            }

            [res, resProcessingRedemptions, res1] = await Promise.all([
                http.getJSON(pendingUrl),
                http.getJSON('/redemptions/getProcessingRedemptions'),
                http.getJSON('/redemptions/getAllBatches')
            ]);
        } else {
            [res, resProcessingRedemptions, res1] = await Promise.all([
                http.getJSON('/redemptions/getAllPendingRedemptions'),
                http.getJSON('/redemptions/getProcessingRedemptions'),
                http.getJSON('/redemptions/getAllBatches')
            ]);
        }
        //let res = await http.getJSON('/redemptions/getAllPendingRedemptions');
        if (res.ok) {
            setRedemptions(res.data.redemptions);
        }

        //let resProcessingRedemptions = await http.getJSON('/redemptions/getProcessingRedemptions');
        if (resProcessingRedemptions.ok) {
            setProcessingRedemptions(resProcessingRedemptions.data.redemptions);
        }

        //let res1 = await http.getJSON('/redemptions/getAllBatches');
        if (res1.ok) {
            setBatches(res1.data.batches);
        }

        setInProgress(false);
    };

    useEffect(() => {
        //AUS will load further down the heirarchy
        if (!isAUSRegion()) {
            (async () => {
                await fetchRedemptionsAndBatches();
            })();
        }
    }, []);

    const handleSelectionCheque = selectedCheque => setSelectedCheque(selectedCheque);

    const handleSelectionNonCheque = selectedNonCheque => setSelectedNonCheque(selectedNonCheque);

    const handleSelectionProcessing = selectedProcessing => setSelectedProcessing(selectedProcessing);

    const handleSelectionProcessingDriver = selectedProcessingDriver =>
        setSelectedProcessingDriver(selectedProcessingDriver);

    const handleSelectionReviewET = selectedReviewET => setSelectedReivewEtransfer(selectedReviewET);

    const handleSelectionReviewBT = selectedReviewBT => setSelectedReivewBtransfer(selectedReviewBT);

    const handleSelectionReviewCheque = selectedReviewCheque => setSelectedReivewCheque(selectedReviewCheque);

    const handleSelectionReviewDriverTransfer = selectedReviewDriverTransfer =>
        setSelectedReivewDriverTransfer(selectedReviewDriverTransfer);

    const handleConfirmationDialog = (
        state,
        paymentTypeToComplete,
        redemptionTypesToComplete,
        underReview = false,
        rejecting = false,
        rejectingProcessing = false
    ) => () => {
        setConfirmationDialogOpen(state);
        setPaymentTypeToComplete(paymentTypeToComplete);
        setRedemptionTypesToComplete(redemptionTypesToComplete);
        setApprovingReview(underReview);
        setRejectingRedemptions(rejecting);
        setRejectingProcessing(rejectingProcessing);
    };

    const handleComplete = async () => {
        setInProgress(true);

        let selection = [];

        if (paymentTypeToComplete === 'CHEQUE') {
            selection = selectedCheque;
        } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
            selection = selectedBankTransfer;
        } else if (paymentTypeToComplete === 'CASH') {
            selection = selectedCash;
        } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
            selection = selectedDriverRedemption;
        } else {
            selection = selectedNonCheque;
        }

        const redemptionsForType = _.filter(
            redemptions,
            redemption =>
                paymentTypeToComplete === _redemption.getPaymentModel(redemption) &&
                redemptionTypesToComplete.includes(_.get(redemption, 'type', 'Customer')) &&
                !_.get(redemption, 'underReview', false)
        );
        let redemption_ids = selection.map(index => ({ _id: redemptionsForType[index]._id }));

        let res = await http.post('/redemptions/completeRedemptions', {
            redemption_ids,
            cashedOutBy: paymentTypeToComplete === 'CASH' ? cashedOutBy : undefined,
            paymentModel: paymentTypeToComplete,
            emailForAPIRedemption
        });
        if (res.ok) {
            setConfirmationDialogOpen(false);
            if (paymentTypeToComplete === 'CHEQUE') {
                setSelectedCheque([]);
            } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
                setSelectedBankTransfer([]);
            } else if (paymentTypeToComplete === 'CASH') {
                setSelectedCash([]);
            } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
                setSelectedDriverRedemption([]);
            } else {
                setSelectedNonCheque([]);
            }
            if (res.status === 207) {
                onSnackbar('Some redemptions could not be processed.', 'info');
            } else {
                onSnackbar('Redemptions successfully processed.');
            }

            await fetchRedemptionsAndBatches();
        } else {
            setConfirmationDialogOpen(false);
            onSnackbar('Issue processing redemptions.', 'error');
        }
        setInProgress(false);
    };
    async function handleApprove() {
        let selection = [];

        if (paymentTypeToComplete === 'CHEQUE') {
            selection = selectedReivewCheque;
        } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
            selection = selectedReivewBtransfer;
        } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
            selection = selectedReivewDriverTransfer;
        } else {
            selection = selectedReivewEtransfer;
        }

        const redemptionsForType = _.filter(
            redemptions,
            redemption =>
                paymentTypeToComplete === _redemption.getPaymentModel(redemption) &&
                redemptionTypesToComplete.includes(_.get(redemption, 'type', 'Customer')) &&
                _.get(redemption, 'underReview', false)
        );

        let redemption_ids = selection.map(index => ({ _id: redemptionsForType[index]._id }));
        setInProgress(true);
        let res = await http.post('/users/ReviewRedemptions', { redemption_ids });
        if (res.ok) {
            setConfirmationDialogOpen(false);
            if (paymentTypeToComplete === 'CHEQUE') {
                setSelectedReivewCheque([]);
            } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
                setSelectedReivewBtransfer([]);
            } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
                setSelectedReivewDriverTransfer([]);
            } else {
                setSelectedReivewEtransfer([]);
            }
            onSnackbar('Redemptions successfully approved.');

            await fetchRedemptionsAndBatches();
        }
        setInProgress(false);
    }

    async function handleReject(isUnderReview = false) {
        let selection = [];
        if (paymentTypeToComplete === 'CHEQUE') {
            selection = isUnderReview ? selectedReivewCheque : selectedCheque;
        } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
            selection = isUnderReview ? selectedReivewBtransfer : selectedBankTransfer;
        } else if (paymentTypeToComplete === 'CASH') {
            selection = selectedCash;
        } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
            selection = isUnderReview ? selectedReivewDriverTransfer : selectedDriverRedemption;
        } else {
            selection = isUnderReview ? selectedReivewEtransfer : selectedNonCheque;
        }

        const redemptionsForType = _.filter(redemptions, redemption => {
            if (isUnderReview) {
                return (
                    paymentTypeToComplete === _redemption.getPaymentModel(redemption) &&
                    redemptionTypesToComplete.includes(_.get(redemption, 'type', 'Customer')) &&
                    _.get(redemption, 'underReview', false)
                );
            } else {
                return (
                    paymentTypeToComplete === _redemption.getPaymentModel(redemption) &&
                    redemptionTypesToComplete.includes(_.get(redemption, 'type', 'Customer')) &&
                    !_.get(redemption, 'underReview', false)
                );
            }
        });

        let redemptionIds = selection.map(index => ({ _id: redemptionsForType[index]._id }));
        setInProgress(true);
        let res = await http.post('/redemptions/reject', { redemptionIds });
        if (res.ok) {
            setConfirmationDialogOpen(false);
            if (paymentTypeToComplete === 'CHEQUE') {
                if (isUnderReview) {
                    setSelectedReivewCheque([]);
                } else {
                    setSelectedCheque([]);
                }
            } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
                if (isUnderReview) {
                    setSelectedReivewBtransfer([]);
                } else {
                    setSelectedBankTransfer([]);
                }
            } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
                if (isUnderReview) {
                    setSelectedReivewDriverTransfer([]);
                } else {
                    setSelectedDriverRedemption([]);
                }
            } else {
                if (isUnderReview) {
                    setSelectedReivewEtransfer([]);
                } else {
                    setSelectedNonCheque([]);
                }
            }
            onSnackbar('Redemptions rejected.');

            await fetchRedemptionsAndBatches();
        }
        setInProgress(false);
    }

    async function handleRejectProcessing() {
        let selection = redemptionTypesToComplete.includes('Driver') ? selectedProcessingDriver : selectedProcessing;
        const redemptionsForType = _.filter(processingRedemptions, redemption => {
            return (
                paymentTypeToComplete === _redemption.getPaymentModel(redemption) &&
                redemptionTypesToComplete.includes(_.get(redemption, 'type', 'Customer'))
            );
        });

        let redemptionIds = selection.map(index => ({ _id: redemptionsForType[index]._id }));
        setInProgress(true);
        let res = await http.post('/redemptions/reject', { redemptionIds });
        if (res.ok) {
            setConfirmationDialogOpen(false);
            if (redemptionTypesToComplete.includes('Driver')) {
                setSelectedProcessingDriver([]);
            } else {
                setSelectedProcessing([]);
            }
            onSnackbar('Redemptions rejected.');

            await fetchRedemptionsAndBatches();
        }
        setInProgress(false);
    }

    let numberSelected = 0;
    if (rejectingProcessing) {
        if (redemptionTypesToComplete.includes('Driver')) {
            numberSelected = selectedProcessingDriver.length;
        } else {
            numberSelected = selectedProcessing.length;
        }
    } else {
        if (paymentTypeToComplete === 'CHEQUE') {
            if (approvingReivew) {
                numberSelected = selectedReivewCheque.length;
            } else {
                numberSelected = selectedCheque.length;
            }
        } else if (paymentTypeToComplete === 'BANK_TRANSFER') {
            if (approvingReivew) {
                numberSelected = selectedReivewBtransfer.length;
            } else {
                numberSelected = selectedBankTransfer.length;
            }
        } else if (paymentTypeToComplete === 'CASH') {
            numberSelected = selectedCash.length;
        } else if (paymentTypeToComplete === 'INTERAC_ETRANSFERS' && redemptionTypesToComplete.includes('Driver')) {
            if (approvingReivew) {
                numberSelected = selectedReivewDriverTransfer.length;
            } else {
                numberSelected = selectedDriverRedemption.length;
            }
        } else {
            if (approvingReivew) {
                numberSelected = selectedReivewEtransfer.length;
            } else {
                numberSelected = selectedNonCheque.length;
            }
        }
    }

    let dialogTitle = `${approvingReivew ? 'Approve' : 'Complete'} redemptions?`;
    let dialogContent = `Are you sure you want to ${
        approvingReivew ? 'approve' : 'complete'
    } ${numberSelected} redemption${numberSelected > 1 ? 's' : ''}?`;
    if (rejectingRedemptions) {
        dialogTitle = 'Reject redemptions?';
        dialogContent = `Are you sure you want to reject ${numberSelected} redemption${numberSelected > 1 ? 's' : ''}?`;
    }

    return (
        <React.Fragment>
            {isAUSRegion() && (
                <AUSTables
                    {...props}
                    redemptions={redemptions}
                    fetchRedemptionsAndBatches={fetchRedemptionsAndBatches}
                    selectedCheque={selectedCheque}
                    selectedNonCheque={selectedNonCheque}
                    batches={batches}
                    onChequeSelection={handleSelectionCheque}
                    onNonChequeSelection={handleSelectionNonCheque}
                    onConfirmationDialog={handleConfirmationDialog}
                    redemptionOptions={redemptionOptions}
                    handleSaveRedemptionOptions={handleSaveRedemptionOptions}
                    handleSelectionReviewCheque={handleSelectionReviewCheque}
                    selectedReivewCheque={selectedReivewCheque}
                    handleSelectionReviewET={handleSelectionReviewET}
                    selectedReivewEtransfer={selectedReivewEtransfer}
                    operator={operator}
                    history={history}
                    inProgress={inProgress}
                />
            )}
            {!isAUSRegion() && (
                <STDTables
                    {...props}
                    redemptions={redemptions}
                    fetchRedemptionsAndBatches={fetchRedemptionsAndBatches}
                    processingRedemptions={processingRedemptions}
                    selectedBankTransfer={selectedBankTransfer}
                    selectedCheque={selectedCheque}
                    selectedNonCheque={selectedNonCheque}
                    selectedCash={selectedCash}
                    selectedProcessing={selectedProcessing}
                    selectedDriverRedemptions={selectedDriverRedemption}
                    selectedProcessingDriver={selectedProcessingDriver}
                    onProcessingSelection={handleSelectionProcessing}
                    onProcessingDriverSelection={handleSelectionProcessingDriver}
                    onChequeSelection={handleSelectionCheque}
                    onNonChequeSelection={handleSelectionNonCheque}
                    onBankTransferSelection={setSelectedBankTransfer}
                    onCashSelection={setSelectedCash}
                    onDriverRedemptionSelection={setSelectedDriverRedemption}
                    onConfirmationDialog={handleConfirmationDialog}
                    batches={batches}
                    redemptionOptions={redemptionOptions}
                    handleSaveRedemptionOptions={handleSaveRedemptionOptions}
                    handleSelectionReviewET={handleSelectionReviewET}
                    selectedReivewEtransfer={selectedReivewEtransfer}
                    handleSelectionReviewBT={handleSelectionReviewBT}
                    selectedReivewBtransfer={selectedReivewBtransfer}
                    handleSelectionReviewCheque={handleSelectionReviewCheque}
                    selectedReivewCheque={selectedReivewCheque}
                    handleSelectionReviewDriverTransfer={handleSelectionReviewDriverTransfer}
                    selectedReivewDriverTransfer={selectedReivewDriverTransfer}
                    operator={operator}
                    history={history}
                    inProgress={inProgress}
                    useAPIRedemptions={useAPIRedemptions}
                />
            )}
            <Dialog open={confirmationDialogOpen} fullWidth onClose={handleConfirmationDialog(false)}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                    {paymentTypeToComplete === 'CASH' && (
                        <TextField
                            value={cashedOutBy}
                            onChange={e => setCashedOutBy(e.target.value)}
                            label="Collector"
                            select
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: theme.spacing.unit * 2, display: 'flex' }}
                        >
                            {collectors.map(collector => (
                                <MenuItem value={_.get(collector, '_id')}>{_collector.getName(collector)}</MenuItem>
                            ))}
                        </TextField>
                    )}
                    {process.env.REACT_APP_ENV !== 'PROD' &&
                        paymentTypeToComplete === 'INTERAC_ETRANSFERS' &&
                        !rejectingRedemptions &&
                        useAPIRedemptions && (
                            <>
                                <TextField
                                    value={emailForAPIRedemption}
                                    onChange={e => setEmailForAPIRedemption(e.target.value)}
                                    label="Test Email for INTERAC Notification (Staging Only)"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    style={{ marginTop: theme.spacing.unit * 2, display: 'flex' }}
                                    error={validate(['email'], emailForAPIRedemption, 'en').fail}
                                    helperText={validate(['email'], emailForAPIRedemption, 'en').reason}
                                />
                                <FormHelperText style={{ marginBottom: theme.spacing.unit * 2 }}>
                                    API Enabled: Completion triggers a live INTERAC email notification. Use a valid BMO
                                    testing email and contact their team to reject/approve request.
                                </FormHelperText>
                            </>
                        )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={inProgress} color="primary" onClick={handleConfirmationDialog(false)}>
                        No
                    </Button>
                    <Button
                        disabled={
                            inProgress ||
                            (paymentTypeToComplete === 'CASH' && _.isEmpty(cashedOutBy)) ||
                            (process.env.REACT_APP_ENV !== 'PROD' &&
                                paymentTypeToComplete === 'INTERAC_ETRANSFERS' &&
                                !rejectingRedemptions &&
                                useAPIRedemptions &&
                                validate(['email'], emailForAPIRedemption, 'en').fail)
                        }
                        data-cy="redemptions-confirm"
                        color="primary"
                        onClick={() => {
                            if (approvingReivew) {
                                if (rejectingRedemptions) {
                                    handleReject(true);
                                } else {
                                    handleApprove();
                                }
                            } else {
                                if (rejectingRedemptions) {
                                    rejectingProcessing ? handleRejectProcessing() : handleReject(false);
                                } else {
                                    handleComplete();
                                }
                            }
                        }}
                    >
                        {inProgress ? <CircularProgress size={16} /> : 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default withTheme()(Redemptions);
